function chatbot() {
    const liveButton = document.getElementById('liveagent_button_online_5730H000000fxWT');

    liveagent.init('https://d.la1-c2-iad.salesforceliveagent.com/chat', '5720H000000fxSQ', '00Di0000000b16R');

    if (!window._laq) {
        window._laq = [];
    }

    window._laq.push(function(){
        liveagent.showWhenOnline('5730H000000fxWT', document.getElementById('liveagent_button_online_5730H000000fxWT'));
        liveagent.showWhenOffline('5730H000000fxWT', document.getElementById('liveagent_button_offline_5730H000000fxWT'));
    });


    liveButton.addEventListener('click', function () {
        liveagent.startChat('5730H000000fxWT')
    });

}


window.addEventListener('load', function () {
    chatbot();
})

